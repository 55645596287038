import React from "react"
import PageRoot from "../../../components/PageRoot"
import ContainerWithPadding from "../../../components/ContainerWithPadding"
import { useTranslation } from "react-i18next"
import InstallmentRequests from "../../../components/payment/InstallmentRequests"

const UserPurchaseListPage = () => {
  const { t } = useTranslation()
  return (
    <PageRoot title={t("label:installmentsRequests")} showTitle>
      <ContainerWithPadding size={"large"}>
        <InstallmentRequests />
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default UserPurchaseListPage
